<template>
	<v-card class="introduce-other-parcours-carousel text-center">
		<h3 class="ortho-results-title mb-1">
			Bonne nouvelle : il reste {{ parcoursNotOwned.length }} parcours à découvrir !!!
		</h3>
		<p class="ortho-description ortho-text-gray">Pour aller encore plus loin continue ton perfectionnement</p>
		<v-carousel hide-delimiters :height="carouselHeight">
			<div class="px-5">
				<CarouselParcourItem
					v-for="parcours in parcoursNotOwned"
					:key="parcours._id"
					:parcours="parcours"
					:hideRuleTagsBlock="shouldHideRuleTagBlock(parcours.topicName)"
				/>
			</div>
		</v-carousel>
		<v-btn
			color="black"
			large
			dark
			class="hvr-grow"
			target="_blank"
			href="https://meetings-eu1.hubspot.com/rendez-vous-csm?uuid=2e0446cd-e95e-42a1-b311-a21d2b36ba22?utm_campaign=end-parcours&utm_source=cta-section-new-parcours-available"
		>
			<strong>C'est parti</strong>
		</v-btn>
	</v-card>
</template>

<script>
import CarouselParcourItem from '@/components/reports/formation/CarouselParcourItem.vue';

export default {
	name: 'IntroduceOtherParcoursCarousel',
	components: { CarouselParcourItem },
	props: { formationConfig: Object, parcoursNotOwned: Array },
	data() {
		return { carouselHeight: 400 };
	},
	methods: {
		// decideWhatParcoursToIntroduce(allParcours, parcoursListItems) {
		// 	// Order in matter. Last pacours finish should be the last position in parcours list item
		// 	//1. Order Asc all parcours by slug. Output: [a, b, c]
		// 	allParcours.sort((a, b) => {
		// 		const slugA = a.slug.toLowerCase();
		// 		const slugB = b.slug.toLowerCase();
		// 		if (slugA < slugB) return -1;
		// 		if (slugA > slugB) return 1;
		// 		return 0;
		// 	});
		// 	//2. Find last poistion of parcours item in formationConfig. Because last position suppose to be the last finish.
		// 	parcoursListItems.sort((a, b) => a.position - b.position);
		// 	const lastestFinishParcoursItem = parcoursListItems[0];
		// 	//3. Find index of lastestFinishParcoursItem in sorted asc allParcours array
		// 	const index = allParcours.findIndex((parcours) => parcours._id === lastestFinishParcoursItem.parcours_id._id);
		// 	//4. Decide next parcours to introduce;
		// 	if (index === -1) return null;
		// 	const nextIndex = index + 1;
		// 	if (nextIndex > allParcours.length - 1) return allParcours[0];
		// 	return allParcours[nextIndex];
		// },
		shouldHideRuleTagBlock(topicName) {
			if (topicName === 'orthographe') return false;
			return true;
		},
		calCarouselHeight() {
			const modalElement = document.querySelector('.p-modal-content');
			const elementHeight = modalElement.offsetHeight;

			if (window.innerWidth < 500) this.carouselHeight = elementHeight + 22;
			else this.carouselHeight = elementHeight;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.calCarouselHeight();
		});
	},
};
</script>

<style lang="scss" scoped>
.introduce-other-parcours-carousel {
	border-radius: 8px;
	padding-top: 1rem;
	padding-bottom: 1rem;
}
</style>
