<template>
	<div class="mt-5 r-container">
		<div v-if="someRulesToDisplay.length === 0" class="rules">
			<div class="ruleChip skeleton" style="width: 133px"></div>
			<div v-if="$vuetify.breakpoint.smAndUp" class="ruleChip skeleton" style="width: 157px"></div>
			<div class="ruleChip skeleton" style="width: 133px"></div>
			<div v-if="$vuetify.breakpoint.smAndUp" class="ruleChip skeleton" style="width: 220px"></div>
			<div class="ruleChip skeleton" style="width: 100px"></div>
			<div class="ruleChip skeleton" style="width: 99px"></div>
		</div>
		<div v-else class="rules">
			<div v-for="ruleTag in someRulesToDisplay" :key="ruleTag._id" class="ruleChip">{{ ruleTag.name }}</div>
			<div class="ruleChip">+{{ totalRule - someRulesToDisplay.length }} autres</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RuleTagsBlock',
	data() {
		return { totalRule: 0, someRulesToDisplay: [] };
	},
	props: { parcours: Object },
	async mounted() {
		this.updateRuleTagsForParcours(this.parcours._id);
	},
	watch: {
		parcours(newParcours) {
			this.someRulesToDisplay = [];
			this.updateRuleTagsForParcours(newParcours._id);
		},
	},
	methods: {
		async updateRuleTagsForParcours(parcoursId) {
			let allRuleTags = [];
			if (this.parcours.ruleTags != null) allRuleTags = this.parcours.ruleTags;
			else
				allRuleTags = await this.$store.dispatch('resources/getSummaryRuleTagsByParcours', { parcoursId: parcoursId });

			// Note: Some rule's name are long. Therefore, select rules that's name not so long.
			const temp = [];
			let index = 0;
			const limitDisplay = window.innerWidth > 600 ? 5 : 3;
			while (temp.length < limitDisplay) {
				if (allRuleTags[index].name.length <= 20) temp.push(allRuleTags[index]);
				index++;
			}

			this.someRulesToDisplay = temp;
			this.totalRule = allRuleTags.length;
		},
	},
};
</script>

<style lang="scss" scoped>
.r-container {
	min-height: 130px;
	@media screen and (max-width: 600px) {
		min-height: 100px;
	}
}
.rules {
	display: flex;
	flex-wrap: wrap;
	max-width: 500px;
	margin: 0 auto;
}

.ruleChip {
	border-radius: 8px;
	background-color: #f0f0f0;
	padding: 5px 12px;
	color: black;
	font-family: 'Lato';
	margin-bottom: 8px;
	margin-right: 4px;
	@media screen and (max-width: 600px) {
		padding: 4px 10px;
	}
}
.skeleton {
	height: 34px;
}
</style>
