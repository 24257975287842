export const changeBgColorById = (id, color) => {
	const ele = document.getElementById(id);
	if (ele == null) return;
	ele.style.backgroundColor = color;
};

export const setWidthForExerciseItems = () => {
	const elements = document.querySelectorAll('.infoSubContainer');
	let lowestWidth = 10000;
	elements.forEach((ele) => {
		if (ele.getBoundingClientRect().width < lowestWidth) lowestWidth = ele.getBoundingClientRect().width;
	});

	elements.forEach((ele) => {
		if (ele.offsetWidth !== lowestWidth) ele.style.width = `${lowestWidth}px`;
	});
};
