<template>
	<v-carousel-item>
		<Content :parcours="parcours" :forceShowRuleTags="true" :hideRuleTagsBlock="hideRuleTagsBlock" />
	</v-carousel-item>
</template>

<script>
import Content from '@/components/dashboard/profile/parcoursModal/Content.vue';

export default {
	name: 'CarouselParcourItem',
	components: { Content },
	props: { parcours: Object, hideRuleTagsBlock: Boolean },
};
</script>

<style lang="scss" scoped></style>
