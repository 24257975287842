<template>
	<div class="thumbnail">
		<img class="parcours-thumbnail" v-if="parcours && parcours.thumbnail" :src="parcours.thumbnail" />
		<v-skeleton-loader v-else type="image" height="250" width="250"></v-skeleton-loader>
		<v-btn v-if="isParcoursDone" medium color="#73e7d2" class="report-btn" @click="toParcoursReport">
			<v-icon size="medium" class="ortho-icon-white mr-1">mdi-eye-outline</v-icon>
			<b><span>Rapport</span></b>
		</v-btn>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ParcoursItemStatus } from '../../../../constants/parcours';

export default {
	name: 'ParcoursThumbnail',
	props: {
		parcours: Object,
		formatioProgressProp: Object,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		isParcoursDone() {
			const useThisFormationProgress = this.formatioProgressProp ? this.formatioProgressProp : this.formationProgress;

			const parcoursItemOfPropParcours = useThisFormationProgress.config.parcours_list.find(
				(parcoursItem) => parcoursItem.parcours_id._id === this.parcours._id
			);
			if (parcoursItemOfPropParcours == null) return false;
			if (parcoursItemOfPropParcours.status === ParcoursItemStatus.completed) return true;
			return false;
		},
		isTheLastInParcoursList() {
			const useThisFormationProgress = this.formatioProgressProp ? this.formatioProgressProp : this.formationProgress;
			const lastParcoursItem = [...useThisFormationProgress.config.parcours_list].sort(
				(a, b) => b.position - a.position
			)[0]; // Get last parcours item in parcours list
			return this.parcours._id === lastParcoursItem.parcours_id._id;
		},
	},
	methods: {
		toParcoursReport() {
			const useThisFormationProgress = this.formatioProgressProp ? this.formatioProgressProp : this.formationProgress;

			if (useThisFormationProgress.formation.for_session) {
				this.$router.push({
					name: this.isTheLastInParcoursList ? 'Redaction Formation Report' : 'Redaction Parcours Report',
					params: { idFormation: useThisFormationProgress.formation._id, idParcours: this.parcours._id },
				});
				return;
			}

			this.$router.push({
				name: this.isTheLastInParcoursList ? 'Report Formation' : 'Report Parcours',
				params: { idFormation: useThisFormationProgress.formation._id, idParcours: this.parcours._id },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.thumbnail {
	width: 250px;
	position: relative;

	@media screen and (max-width: 400px) {
		width: 200px;
	}
}
.parcours-thumbnail {
	width: 100%;
}

.report-btn {
	width: 110px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	box-shadow: none;
}
</style>
