<template>
	<div class="p-modal-content">
		<div class="two-cols">
			<div class="left">
				<ParcoursThumbnail :parcours="parcours" :formatioProgressProp="formationProgressOfParcours" />
			</div>
			<div class="right">
				<h3 class="ortho-description">{{ parcours.name }}</h3>
				<p class="ortho-results-text">{{ parcours.description }}</p>
				<div class="ortho-results-text">
					<p class="mb-0">
						Difficulté : <strong>{{ parcours.difficulty }}</strong>
					</p>
					<p v-if="hideRuleTagsBlock !== true && parcours.topicSlug !== 'redaction'">
						Nombre de règle : <strong>80</strong>
					</p>
					<div v-else style="height: 25.5px"></div>
				</div>
			</div>
		</div>
		<div
			v-if="hideRuleTagsBlock !== true && parcours.topicSlug !== 'redaction'"
			:class="{ 'hide-small-screen': forceShowRuleTags !== true }"
		>
			<p class="ortho-text-gray"><span>🔎</span> Quelques règles que vous allez rencontrer</p>
		</div>
		<template v-if="hideRuleTagsBlock !== true">
			<RuleTagsBlock
				v-if="parcours.topicSlug !== 'redaction'"
				:parcours="parcours"
				:class="{ 'hide-small-screen': forceShowRuleTags !== true }"
			/>
		</template>

		<div class="blank-space-redaction" v-if="parcours.topicSlug === 'redaction'"></div>
	</div>
</template>

<script>
import RuleTagsBlock from './RuleTagsBlock';
import ParcoursThumbnail from './ParcoursThumbnail';

export default {
	name: 'Content',
	components: { RuleTagsBlock, ParcoursThumbnail },
	props: {
		parcours: Object,
		forceShowRuleTags: Boolean,
		formationProgressArr: Array,
		hideRuleTagsBlock: Boolean,
	},
	data() {
		return {
			formationProgressOfParcours: null,
		};
	},
	mounted() {
		this.findFormationProgressForParcours();
	},
	watch: {
		parcours() {
			this.findFormationProgressForParcours();
		},
	},
	methods: {
		findFormationProgressForParcours() {
			if (this.formationProgressArr == null) return;

			// Find formation Config has this parcours ID
			const formationProgressHaveThisParcoursId = this.formationProgressArr.find((progress) => {
				return progress.config.parcours_list.find((parcoursItem) => {
					return parcoursItem.parcours_id._id === this.parcours._id;
				});
			});
			this.formationProgressOfParcours = formationProgressHaveThisParcoursId;
		},
	},
};
</script>

<style lang="scss" scoped>
.p-modal-content {
	.two-cols {
		display: flex;
		align-items: center;

		.left {
			min-height: 250px;
		}
		.right {
			text-align: left;
			padding-right: 20px;
		}
	}

	.blank-space-redaction {
		height: 174px;
	}

	@media screen and (max-width: 600px) {
		.two-cols {
			display: flex;
			flex-direction: column;

			.left {
				min-height: 170px;
			}

			.right {
				text-align: center;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		.blank-space-redaction {
			height: 17px;
		}
	}

	@media screen and (max-width: 959px) {
		.hide-small-screen {
			display: none;
		}
	}
}
</style>
