import { render, staticRenderFns } from "./RuleTagsBlock.vue?vue&type=template&id=44e1574b&scoped=true&"
import script from "./RuleTagsBlock.vue?vue&type=script&lang=js&"
export * from "./RuleTagsBlock.vue?vue&type=script&lang=js&"
import style0 from "./RuleTagsBlock.vue?vue&type=style&index=0&id=44e1574b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44e1574b",
  null
  
)

export default component.exports